import './unsolicited-ratesheet-details.scss';

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import Collapse, { Panel } from 'rc-collapse';
import { Column } from 'react-display-flex';
import { FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getSettings } from '../../../../../../actions/session-selector';
import { issuerAcceptBespokeUnsolicitedRatesheet } from '../../../../../../api/unsolicited-ratesheets/issuer/accept-bespoke-unsolicited-ratesheet';
import { issuerAcceptDefaultUnsolicitedRatesheet } from '../../../../../../api/unsolicited-ratesheets/issuer/accept-default-unsolicited-ratesheet';
import { DateWithTimeZone, Drawer } from '../../../../../../components/common';
import { collapseMotion } from '../../../../../../components/common/css-motions';
import { couponFormat } from '../../../../../../format-numbers';
import { Money } from '../../../../../money';
import { Tenors } from '../../../../../Tenors/Tenors';
import { MessageType, showResponseErrorMessage, showToastMessage } from '../../../../../toast/toast';
import { UnsolicitedRatesheetOnBehalfPopover } from './UnsolicitedRatesheetOnBehalfPopover';

const expandIcon = ({ isActive }) => (isActive ? <FaChevronCircleUp /> : <FaChevronCircleDown />);

const panelKeys = {
  currentRatesheet: 'currentRatesheet',
};

export const UnsolicitedRatesheetDetailsComponent = injectIntl(
  ({
    bankRatesheet,
    customerCurrentRatesheet,
    onClose,
    selectedCustomer,
    unsolicitedRatesheetMaxBankAmount,
    onAddOnBehalfConfirm,
    intl,
  }) => {
    const [tenorItemRef, setTenorItemRef] = useState(false);
    const [selectedTenor, setSelectedTenor] = useState(undefined);

    useEffect(() => {
      if (!tenorItemRef || !tenorItemRef.current) {
        return;
      }
    }, [tenorItemRef]);

    const onConfirm = async (body) => {
      const { rate, principal, allocationCode, tenor, interestPaid, additionalFunds } = body;

      try {
        const addOnBehalfAction =
          customerCurrentRatesheet.type === 'default'
            ? issuerAcceptDefaultUnsolicitedRatesheet
            : issuerAcceptBespokeUnsolicitedRatesheet;

        await addOnBehalfAction({
          rate,
          principal,
          allocationCode,
          tenor,
          interestPaid,
          additionalFunds,
          investorTenantId: selectedCustomer.investorTenantId,
          unsolicitedRatesheetEntityId: customerCurrentRatesheet.unsolicitedRatesheetEntityId,
          ratesheet: customerCurrentRatesheet.ratesheet,
        });

        onAddOnBehalfConfirm();

        setSelectedTenor(undefined);

        showToastMessage(intl.formatMessage({ id: 'savedSuccessfully' }), MessageType.SUCCESS);
      } catch (error) {
        showResponseErrorMessage({ intl, error });
      }
    };

    return (
      <Drawer
        contentAriaLabel="unsolicited ratesheet details"
        className="unsolicited-ratesheet-details"
        open={!!selectedCustomer}
        onClose={() => {
          onClose();
          setSelectedTenor(undefined);
        }}
        titleId="unsolicitedRatesheetDetails"
        width="40vw"
      >
        <Column>
          {customerCurrentRatesheet && (
            <Collapse
              openMotion={collapseMotion}
              expandIcon={expandIcon}
              defaultActiveKey={[panelKeys.currentRatesheet]}
            >
              <Panel
                key={panelKeys.currentRatesheet}
                header={
                  <FormattedMessage
                    id="currentRatesheet"
                    values={{
                      type: customerCurrentRatesheet.type,
                      updatedAt: new Date(customerCurrentRatesheet.ratesheet.updatedAt),
                      small: (msg) => <small>{msg}</small>,
                    }}
                    tagName="h3"
                  />
                }
              >
                <Tenors
                  readOnly={!!selectedTenor}
                  status="highlight"
                  ratesheet={customerCurrentRatesheet.ratesheet}
                  selectedTenor={selectedTenor?.tenor}
                  onClick={({ tenor, rate, ref }) => {
                    setTenorItemRef(ref);
                    setSelectedTenor({ tenor, rate });
                  }}
                />
                {selectedTenor && selectedCustomer && (
                  <UnsolicitedRatesheetOnBehalfPopover
                    customer={selectedCustomer}
                    onConfirm={onConfirm}
                    tenor={selectedTenor?.tenor}
                    rate={selectedTenor?.rate}
                    show={!!selectedTenor}
                    onHide={() => setSelectedTenor(undefined)}
                    targetRef={tenorItemRef}
                    unsolicitedRatesheetMaxBankAmount={unsolicitedRatesheetMaxBankAmount}
                  />
                )}
              </Panel>
            </Collapse>
          )}
          {selectedCustomer?.trades?.length ? <FormattedMessage id="createdTrades" tagName="h3" /> : null}
          <section aria-label="created trades">
            <Collapse aria-label="test" openMotion={collapseMotion} expandIcon={expandIcon}>
              {selectedCustomer?.trades?.map((trade, index) => (
                <Panel
                  header={
                    <dl>
                      <Column>
                        <dt>
                          <FormattedMessage id="tenor" />
                        </dt>
                        <dd>{trade.tenor}</dd>
                      </Column>
                      <Column>
                        <dt>
                          <FormattedMessage id="principal" />
                        </dt>
                        <dd>
                          <Money value={trade.principal} />
                        </dd>
                      </Column>
                      <Column>
                        <dt>
                          <FormattedMessage id="createdAt" />
                        </dt>
                        <dd>
                          <DateWithTimeZone dateStyle="short" timeStyle="short" value={trade.createdAt} />
                        </dd>
                      </Column>
                      <Column>
                        <dt>
                          <FormattedMessage id="coupon" />
                        </dt>
                        <dd>
                          <FormattedNumber {...couponFormat} value={trade.bankRate} />
                        </dd>
                      </Column>
                      <Column>
                        <dt>
                          <FormattedMessage id="unsolicitedRatesheetType" />
                        </dt>
                        <dd>
                          <FormattedMessage id={trade.unsolicitedRatesheetType} />
                        </dd>
                      </Column>
                    </dl>
                  }
                  key={`${trade.unsolicitedRatesheetEntityId}${index}`}
                >
                  <dl>
                    <Column>
                      <dt>
                        <FormattedMessage id="interestPaid" />
                      </dt>
                      <dd>
                        <FormattedMessage id={`interestDescription.${trade.interestPaid}`} />
                      </dd>
                    </Column>
                    <Column>
                      <dt>
                        <FormattedMessage id="holdingMaturityDate" />
                      </dt>
                      <dd>
                        <DateWithTimeZone value={trade.maturityDate} />
                      </dd>
                    </Column>
                    {trade.bankUserId && (
                      <Column>
                        <dt>
                          <FormattedMessage id="unsolicitedRatesheetOnBehalf" />
                        </dt>
                        <dd>
                          <FormattedMessage id="yes" />
                        </dd>
                      </Column>
                    )}
                  </dl>
                  <Column>
                    <FormattedMessage tagName="h4" id="ratesheet" />
                    <Tenors selectedTenor={trade.tenor} readOnly ratesheet={trade.ratesheet} status="accepted" />
                  </Column>
                </Panel>
              ))}
            </Collapse>
          </section>
        </Column>
      </Drawer>
    );
  },
);

UnsolicitedRatesheetDetailsComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.shape(),
  customerCurrentRatesheet: PropTypes.shape({
    type: PropTypes.string,
    ratesheet: PropTypes.shape(),
  }),
  bankRatesheet: PropTypes.shape({
    available: PropTypes.bool,
    isFetching: PropTypes.bool,
    updatedAt: PropTypes.string,
    rates: PropTypes.arrayOf(
      PropTypes.shape({
        rate: PropTypes.number,
        tenor: PropTypes.string,
      }),
    ),
  }),
};

UnsolicitedRatesheetDetailsComponent.defaultProps = {
  open: false,
};

const mapStateToProps = (state) => {
  const tenantSettings = getSettings(state);

  return {
    unsolicitedRatesheetMaxBankAmount: tenantSettings && tenantSettings.unsolicitedRatesheetMaxBankAmount,
  };
};

export const UnsolicitedRatesheetDetails = connect(mapStateToProps)(UnsolicitedRatesheetDetailsComponent);
