import _ from 'lodash';

import { tradeSources } from '../api/holding/codes';

/* eslint-disable no-template-curly-in-string */
const holding = {
  tenant: 'Tenant',
  type: 'Type',
  go: 'Go',
  holdingBankingGroup: 'Banking Group',
  holdingIssuer: 'Issuer',
  holdingRating: 'Rating',
  holdingAllocation: 'Allocation',
  settlementDate: 'Settlement Date',
  holdingSettlementDate: 'Settlement Date',
  holdingPurchaseDate: 'Purchase Date',
  holdingSellingDate: 'Selling Date',
  holdingSellingPrice: 'Selling Price',
  holdingMaturityDate: 'Maturity Date',
  holdingTransactionDate: 'Transaction Date',
  holdingNextCouponDate: 'Next Coupon Date',
  holdingLastCouponDate: 'Last Coupon Date',
  holdingColumnMonthToDate: 'MTD ($)',
  holdingColumnCleanPrice: 'Purchase Clean Price ($)',
  holdingColumnGrossPrice: 'Purchase Gross Price ($)',
  holdingEffectiveCleanPriceColumn: 'Effective Clean Price ($)',
  holdingEffectiveGrossPriceColumn: 'Effective Gross Price ($)',
  holdingColumnYieldTradeMargin: 'Yield (%)/Trade Margin (bp)',
  holdingColumnFrnIssueMargin: 'FRN Issue Margin (bp)',
  holdingColumnBondIssueMargin: 'Bond Issue Margin (%)',
  holdingColumnSource: 'Source',
  holdingColumnNote: 'Notes',
  holdingColumnReference: 'Reference',
  holdingColumnBankReference: 'Bank Reference',
  monthToDate: 'MTD',
  instrumentQuantity: 'Instrument Quantity',
  frnIssueMargin: 'FRN Issue Margin',
  holdingCoupon: 'Coupon (%)',
  unsettledSettlementDate: 'Settlement Date',
  principal: 'Principal',
  customHoldingPrincipal: 'Principal ({currencySymbol})',
  customHoldingPrincipalToKeep: 'Principal to keep ({currencySymbol})',
  holdingCompound: 'Compound',
  holdingPaid: 'Paid',
  addPortfolioTitle: 'Add Trade',
  holdingCleanPrice: 'Clean Price',
  holdingGrossPrice: 'Gross Price',
  holdingInstrumentCode: 'Asset Class',
  createdAt: 'Created at',
  lastUpdated: 'Last updated',
  holdingEndTime: 'End Time',
  holdingIsins: 'ISIN',
  holdingBonds: 'Bonds',
  holdingCash: 'Invested Cash',
  holdingECD: 'ECDs',
  holdingCP: 'CPs',
  holdingFRN: 'FRNs',
  holdingNCD: 'NCDs',
  holdingsOperationTitle: 'Select Action',
  holdingAllocationCode: 'Allocation',
  selectAnAction: 'Select an action',
  holdingNote: 'Note',
  holdingReference: 'Reference',
  holdingBankReference: 'Bank Reference',
  holdingTradeId: 'Trade ID',
  addTrade: 'Add Trade',
  addNewTrade: 'Add new trade',
  addSingleCustomer: 'Add single customer',
  addMultipleCustomers: 'Add multiple customers',
  updateCashOption: 'Update Cash',
  balanceUpdateDate: 'Balance Update Date',
  tradeSavedSuccessfully: 'Trade added successfully',
  backToHoldings: 'Back to Holdings',
  holdingTradeTitle: 'Current Holding',
  holdingResultsTitle: 'Holdings',
  portfolioPercentage: 'Portfolio %',
  accruedInterest: 'Accr. Int. ({currencySymbol})',
  confirmLeavingTitle: 'Confirm leaving without save?',
  confirmLeavingBody: 'Leave this page? You have unsaved changes that will be lost.',
  confirmLeavingPositiveAnswer: 'Yes, leave the page and lose changes',
  confirmLeavingNegativeAnswer: 'No, continue in the page',
  confirmSavingTitle: 'Confirm saving data?',
  confirmSavingBody: 'Some of the data to save may require your attention',
  confirmSaveSellBonds: 'Sell ${sell, number} and keep ${keep, number} invested on the current isin (${isin})?',
  confirmSavingPositiveAnswer: 'Yes, save the data and proceed',
  confirmSavingNegativeAnswer: 'No, change the data before proceeding',
  confirmRedeemTitle: 'Confirm redeem trade',
  confirmReinvestTitle: 'Confirm reinvestment',
  confirmRedeemBody: 'Redeem this trade? An e-mail will be sent to the ADI requesting its redemption.',
  confirmRedeemBodyWithOffer:
    'You have a pending offer.\nAre you sure you want to redeem this trade? An e-mail will be sent to the ADI requesting its redemption.',
  confirmRedeemFooter:
    'Once the redemption is confirmed with the ADI, change the status to "Confirmed" to remove the trade from the current portfolio',
  confirmRedeemPositiveAnswer: 'Redeem',
  confirmRedeemNegativeAnswer: 'Cancel',
  confirmRfqTitle: 'Confirm completed',
  confirmRfqReinvestWithOffer:
    'You have a pending offer. Are you sure that you want to reinvest this trade? It will reject the pending offer.',
  confirmRfqBody: 'Are you sure that you want to mark this trade as completed?',
  confirmRfqNegativeAnswer: 'No',
  confirmRfqPositiveAnswer: 'Yes',
  addTermDepositOption: 'Add Term Deposit',
  addTermAnnuityOption: 'Add Term Annuity',
  addBuyBondsOption: 'Buy Bonds',
  addSellBondsOption: 'Sell Bonds',
  addSellECDOption: 'Sell CDs',
  addSellCPOption: 'Sell CPs',
  addSellFRNOption: 'Sell FRN',
  addSellNCDOption: 'Sell NCDs',
  addBuyFrnsOption: 'Buy FRNs',
  addBuyFrtdsOption: 'Buy FRTDs',
  addCashOption: 'Add Cash',
  addECDOption: 'Add CD',
  addCPOption: 'Add CP',
  multipleOfStep: 'Must be multiple of ${step, number}',
  promptTextCreator: 'Press ENTER to create option {option}',
  presenterSellableOption: '{isin} ({issuerCode}) - {maturity} - Total Invested: {total}',
  presenterSellableCDOption: '({issuerCode}) - {maturity} - Total Invested: {total}',
  presenterCashOption: '({issuerCode}) {reference} - {total}',
  callableDate: 'Callable date',
  quoteAmount: '{length, plural, one {quote} other {# quotes}} received',
  showTour: 'Start tour.',
  updateTradeNoteAndReferences: 'Update trade note and references',
  downloadBalanceHistory: 'Download balance history',
  marginTradeLabel: 'Trading Margin (bp)',
  yieldTradeLabel: 'Yield (%)',
  faceValueToSell: 'Face Value to Sell ({currency})',
  faceValueToBuy: 'Face Value to Buy ({currency})',
  faceValue: 'Face Value ({currency})',
  securitiesFaceValue: 'Face Value',
  safeCustody: 'Safe Custody',
  standardSettlementDate: 'Standard Settlement Date (T+2)',
  selectBank: 'Select Bank',
  selectedBanks: '{length, plural, =0 {No banks} one {1 bank} other {# banks}} selected',
  tradeRequestQuotes: 'Request Quote',
  minParcelToSell: 'Min parcel to sell',
  thereafterDenominations: 'Thereafter denominations of',
  sellAll: 'Sell all',
  quotesRequestedSuccessfully: 'Quotes requested successfully',
  requestQuotesConfirmation:
    'You are about to seek quotes for selling {sellingValue} out your total holdings of {holdingValue} in this security, leaving you a total balance of {differenceValue}. Are you sure?',
};

const compliances = {
  'compliances.holdings': 'Holdings',
  'compliances.unsettled': 'Unsettled',
  'compliances.noUnsettledFound': 'There are no unsettled trades at the moment',
  'compliances.counterparty': 'Counterparty',
  'compliances.credit-quality': 'Credit Quality',
  'compliances.maturity': 'Maturity',
  'compliances.allocation': 'Allocation',
  'compliances.asset-class': 'Asset Class',
  maturityLabel: 'Rule',
  complianceAmountInvested: 'Invested',
  complianceAmountAvailable: 'Available',
  complianceInvestmentSharePct: 'Invested (%)',
  offLimit: 'Excess/Deficit',
  complianceMaxLimitPct: 'Max Limit (%)',
  complianceMinLimitPct: 'Min Limit (%)',
  bankingGroupMaxMoneyLimit: 'Max Money Limit',
  possibleComplianceBreach: 'Possible Compliance Breach',
};

const interestDescription = {
  'interestDescription.M': 'Monthly',
  'interestDescription.Q': 'Quarterly',
  'interestDescription.S': 'Semi-Annual',
  'interestDescription.A': 'Annual',
  'interestDescription.Z': 'At Maturity',
};

const filter = {
  'filter.maturing': `{days, plural,
    =0 {Maturing today}
    one {Until tomorrow}
    other {In the next # days}
  }`,
  'filter.All': 'All',
  'filter.Bond': 'Bond',
  'filter.Cash': 'Cash',
  'filter.ECD': 'ECD',
  'filter.CP': 'CP',
  'filter.FRN': 'FRN',
  'filter.FRTD': 'FRTD',
  'filter.TAN': 'TAN',
  'filter.Otherasset': 'Other',
  'filter.Mgfund': 'MGFUND',
  'filter.General': 'General',
  'filter.Long': 'Long',
  'filter.Other': 'Other',
  'filter.Sewer': 'Sewer',
  'filter.Short': 'Short',
  'filter.TD': 'TD',
  'filter.Water': 'Water',
  'filter.allocationCode': 'Allocation',
  'filter.false': 'No',
  'filter.instrumentCode': 'Asset class',
  'filter.isCompliant': 'Is Compliant',
  'filter.term': 'Term',
  'filter.true': 'Yes',
};

const term = {
  'term.short': 'Short',
  'term.long': 'Long',
};

const upload = {
  'upload.tradesCount': 'Number of trades',
  'upload.totalValue': 'Portfolio value',
  'upload.errorMessage': 'Error message',
  'upload.uploadSucceeded': 'Upload completed successfully',
  'upload.uploadFailed': 'Upload failed',
  'upload.uploadingFileMessage': 'Wait as the file is being uploaded',
  'upload.resultsHeader': 'Upload Results',
  'upload.linesErrorsHeader': 'Errors on lines',
  'upload.lineNumber': 'Line number',
  'upload.errorsHeader': 'Errors',
  'upload.dropzoneMessage': 'Drop files here or click to upload.',
  'upload.mainHeader': 'Upload trades',
};

const menu = {
  'menu.home': 'Home',
  'menu.addTrade': 'Add Trade',
  'menu.addTradeShortcut': 'Add Trade',
  'menu.admin-maturing-email': 'Emailing',
  'menu.admin-maturing-trades': 'Trades maturing',
  'menu.admin-fees': 'Fees',
  'menu.admin-rfqs': 'RFQs',
  'menu.admin-update-password': 'Update password',
  'menu.admin-portfolio-performance': 'Portfolio performance',
  'menu.main': 'Main',
  'menu.newRfq': 'New RFQ',
  'menu.newRfqShortcut': 'New RFQ',
  'menu.portfolio': 'Portfolio',
  'menu.reports': 'Reports',
  'menu.capitalValue': 'Capital Value',
  'menu.quotes': 'Quotes Received',
  'menu.trade-list': 'Trade Listing',
  'menu.update-ratesheet': 'Ratesheet update',
  'menu.incoming-rfqs': 'Incoming RFQs',
  'menu.outgoing-rfqs': 'Outgoing RFQs',
  'menu.upload': 'Upload Trades',
  'menu.sandbox': 'Sandbox',
  'menu.dashboard': 'Dashboard',
  'menu.onboarding': 'Customers Onboarding',
  'menu.reports.incomingRfqsHistory': 'Incoming RFQs history',
  'menu.reports.outgoingRfqsHistory': 'Outgoing RFQs history',
  'menu.unsolicitedRatesheets': 'Digital Ratesheets',
};

const dashboard = {
  'dashboard.incomingRfqs': 'Incoming RFQs',
  'dashboard.outgoingRfqs': 'Outgoing RFQs',
  'dashboard.action.quote': 'Quote',
  'dashboard.action.confirm': 'Confirm',
  'dashboard.action.view': 'View',
  'dashboard.action.edit': 'Edit',
  'dashboard.status.open': 'Open',
  'dashboard.status.pending': 'Pending',
  'dashboard.status.ongoing': 'Ongoing',
  'dashboard.status.won': 'Won',
  'dashboard.status.lost': 'Lost',
  'dashboard.status.nothing-done': 'Nothing done',
  'dashboard.status.dealt-away': 'Dealt off-platform',
  'dashboard.status.cancelled': 'Cancelled',
  'dashboard.status.closed': 'Closed',
  'dashboard.mainTitle': 'Dashboard',
  'dashboard.assetClass': 'Asset',
  'dashboard.status': 'Status',
  'dashboard.customer': 'Customer',
  'dashboard.amount': 'Amount',
  'dashboard.settlementDate': 'Settlement',
  'dashboard.type': 'Type',
  'dashboard.current': 'Current',
  'dashboard.responses.sent': 'Sent',
  'dashboard.responses.received': 'Rec.',
  'dashboard.new': '(New trade)',
  'dashboard.reinvestment': 'Reinvestment',
  'dashboard.action.selectQuote': 'Select quote',
  'dashboard.adiTrades.time': 'Time',
  'dashboard.adiTrades.operation': 'Oper',
  'dashboard.adiTrades.maturity': 'Maturity',
  'dashboard.adiTrades.type': 'Type',
  'dashboard.adiTrades.counterparty': 'Counterparty',
  'dashboard.adiTrades.amount': 'Amount',
  'dashboard.adiTrades.settlementDate': 'Settlement',
  'dashboard.adiTrades.rate': 'Rate',
  'dashboard.adiTrades.title': 'ADI Trades',
  'dashboard.tag.notConfirmed': 'NC',
  'dashboard.dialog.incomingRfqQuotes': 'Incoming RFQ quotes',
  'dashboard.dialog.basisPointFeeDiscounted': 'Rates displayed to investor will be discounted by {basisPointFee} bp.',
  'dashboard.dialog.anonymisedQuotesAvailableSubscribers':
    'Note: Anonymised quotes from other ADIs are available for RFQ Reporting subscribers.',
  'dashboard.dialog.outgoingRfqQuotes': 'Outgoing RFQ quotes',
  'dashboard.dialog.bankColumn': 'Bank',
  'dashboard.dialog.thisAdi': 'Your ADI',
  'dashboard.notifications.incomingAndOutgoingPending': 'You have pending incoming and outgoing RFQs',
  'dashboard.notifications.incomingPending': 'You have pending incoming RFQs',
  'dashboard.notifications.outgoingPending': 'You have pending outgoing RFQs',
  'dashboard.downloadCsv': 'Download',
  bankDashboardSendEmailSubject: 'Maturing investment - {issuer} - {maturityDate}',
  bankDashboardSendEmailBody: `

Maturing investment details:

- Asset Class: {assetClass}
- Principal: {principal}
- Coupon: {coupon}%
- Maturing on: {maturityDate}
  `,
};

const reports = {
  'reports.rfqsHistory.assetClass': 'Instr.',
  'reports.rfqsHistory.coupon': 'Quote',
  'reports.rfqsHistory.quoteSent': 'Quote sent',
  'reports.rfqsHistory.basisPointFee': 'Fee bp',
  'reports.rfqsHistory.customer': 'Counterparty',
  'reports.rfqsHistory.dealer': 'Dealer',
  'reports.rfqsHistory.date': 'Date',
  'reports.rfqsHistory.downloadCsvButton': 'Download',
  'reports.rfqsHistory.fees': 'Fees',
  'reports.rfqsHistory.filterByDateButton': 'View',
  'reports.rfqsHistory.fromDate': 'From',
  'reports.rfqsHistory.incomingMainTitle': 'Incoming RFQs history',
  'reports.rfqsHistory.maturity': 'Maturity',
  'reports.rfqsHistory.tenor': 'Tenor',
  'reports.rfqsHistory.maturityDate': 'Maturity',
  'reports.rfqsHistory.outgoingMainTitle': 'Outgoing RFQs history',
  'reports.rfqsHistory.principal': 'Principal',
  'reports.rfqsHistory.faceValue': 'Face value',
  'reports.rfqsHistory.capitalValue': 'Cap. value',
  'reports.rfqsHistory.grossValue': 'Gross value',
  'reports.rfqsHistory.status': 'Status',
  'reports.rfqsHistory.termDays': 'Term (days)',
  'reports.rfqsHistory.time': 'Time',
  'reports.rfqsHistory.toDate': 'To',
  'reports.rfqsHistory.viewQuotes': 'View quotes',
  'reports.rfqsHistory.details': 'Details',
  'reports.rfqsHistory.viewQuotesButton': 'View',
  'reports.rfqsHistory.winningCoupon': 'Win. quote',
  'reports.rfqsHistory.createdBy': 'Created',
  'reports.rfqsHistory.acceptedBy': 'Accepted',
  'reports.rfqsHistory.confirmedBy': 'Conf.',
  'reports.rfqsHistory.cancelledBy': 'Canc.',
  'reports.rfqsHistory.nominatedBy': 'Nomin.',
  'reports.rfqsHistory.signedOffBy': 'Sign-off',
  'reports.rfqsHistory.nextBestCoupon': 'Next best',
  'reports.rfqsHistory.quotedBy': 'Quoted by',
  'reports.rfqsHistory.dealtBy': 'Dealt by',
  'reports.rfqsHistory.margin': 'Margin b.p.',
  'reports.rfqsHistory.winningIssuer': 'Winning',
  'reports.rfqsHistory.source': 'Source',
  'reports.rfqsHistory.operation': 'Oper.',
  'reports.rfqsHistory.creditRating': 'Credit rating',
  'reports.rfqsHistory.avgWinMargin': 'Average winning margin',
  'reports.rfqsHistory.avgLoseMargin': 'Average losing margin',
  'reports.rfqsHistory.largestWinMargin': 'Largest winning margin',
  'reports.rfqsHistory.largestLoseMargin': 'Largest losing margin',
  'reports.rfqsHistory.basisPoints': 'Basis points',
  'reports.rfqsHistory.noData': 'There are no rfqs for the current filter.',
  'reports.rfqsHistory.settlementDate': 'Settlement',
  selectAll: 'Select All',
  unselectAll: 'Unselect All',
  portfolioReports: 'Portfolio',
  customizeReport: 'Customize Report',
  tradesReports: 'Trades',
  generatePdfReport: 'Generate Report',
  downloadPdfReport: 'Download Report',
  selectReports: 'Select Reports',
  reportDownloads: 'Portfolio reporting',
  selectPeriod: 'Select Period',
  reports: 'Reports',
  performanceReport: 'Performance',
  'reports.transactions': 'Transactions',
  'reports.standard': 'Standard',
  'reports.extras': 'Extras',
  'reports.portfolioValuation': 'Portfolio',
  'reports.portfolioByAsset': 'Portfolio By Asset',
  'reports.counterpartyCompliance': 'Counterparty Compliance',
  'reports.creditQualityCompliance': 'Credit Quality Compliance',
  'reports.maturityCompliance': 'Maturity Compliance',
  'reports.tradesForPeriod': 'Trades for Period',
  'reports.realisedGainsLosses': 'Realised Gains/Losses',
  'reports.unrealisedGainsLosses': 'Unrealised Gains/Losses',
  'reports.interestInPeriod': 'Interest Received',
  'reports.historicalBalance': 'Portfolio Balance/Ratios',
  'reports.maturityCashFlows': 'Cashflow - Principal',
  'reports.portfolioComparison': 'Comparison Report',
  'reports.allocation': 'Allocation',
  'reports.instrument': 'Asset Class',
  'reports.fossilFuels': 'Fossil Fuel Funding',
  performanceActual: 'Performance (Actual)',
  performanceAnnualised: 'Performance (Annualised)',
  performancePeriod:
    '{period, select, 1M {1 Month} 3M {3 Months} 6M {6 Months} 12M {1 Year} YTD {FYTD} other {{period}}} (%)',
  performanceInstrumentName:
    '{name, select, combined {Portfolio Performance} outperformance {Outperformance} actual {Performance (Actual)} annualised {Performance (Annualised)} other {{name}}}',
  ytd: 'FYTD (%)',
  mtd: 'MTD (%)',
  generateReportForDate: 'Generate a report for {date}? {processingTimeInfo}',
  generateReportForDuplicatedDate:
    'There is already a report created for the selected date {date}. Are you sure you want to regenerate it? {processingTimeInfo}',
  performanceInstrumentType: 'Instruments',
  performanceIndexType: 'Indexes',
  performancePerformanceType: 'Performance',
  performanceBenchmarkIndexType: 'Benchmark Index',
  includeCash: 'Include Cash',
};

const counterparty = {
  'counterparty.shortHoldings': 'Counterparty compliance: short-term holdings',
  'counterparty.longHoldings': 'Counterparty compliance: long-term holdings',
  'counterparty.resultsTitle': 'Counterparty compliance by term',
};

const creditQuality = {
  'credit-quality.shortHoldings': 'Credit quality compliance: short-term holdings',
  'credit-quality.longHoldings': 'Credit quality compliance: long-term holdings',
  'credit-quality.resultsTitle': 'Credit quality compliance by rating',
};

const maturity = {
  maturityChartTitle: 'Maturity compliance',
  'maturity.resultsTitle': 'Maturity compliance by period',
};

const needToConfirm = {
  needToConfirmTitle: 'Quote accepted',
  needToConfirmFirstParagraph: 'An e-mail was sent to {issuerCode} informing that their quote has been selected',
  needToConfirmSecondParagraph: 'When you have contacted {issuerCode}, update to confirmed',
};

const allocation = {
  allocationChartTitle: 'Account breakdown',
  'allocation.resultsTitle': 'Investments per allocation',
};

const assets = {
  assetsChartTitle: 'Breakdown by asset type',
  'assets.resultsTitle': 'Investments per assets',
};

const download = {
  'download.chart': 'Download Chart',
  'download.csv': "Today's trades",
  'download.csvAll': 'All trades',
  'download.csvGrouped': 'Grouped by Issuer',
  'download.redemptions': 'All Redemptions',
  'download.label': 'Download Trades',
  'download.prompt': 'Download...',
  download: 'Download',
};

const errors = {
  goBack: 'Go back',
  takeMeHome: 'Take me home',
  'contact.1': 'If you think this is a mistake, please contact the ',
  'contact.2': 'Imperium Markets administrator.',
  'notFound.message': 'The page you are looking for could not be found.',
  'notFound.info': 'Make sure the address is correct and that the page has not moved.',
  'internalError.message': 'Internal Server Error.',
  'internalError.info': 'Sorry, there was an unexpected server error.',
  'forbidden.message': 'Sorry, you do not have permission to access this page.',
  'error-invalid-operation-reinvested': 'This trade has already been reinvested',
  sorryThereWasAnError: 'Sorry, there was an unexpected error.',
  'file-greater-than-max-allowed-size': 'The file size is greater than {size}.',
  'filename-greater-than-max': 'The filename is is greater than {characters} characters.',
};

const portfolio = {
  'portfolio.filter-investments-number': '{length, plural, =0 {None} one {One investment} other {# investments}}',
  'portfolio.filter-quotes-received-number': '{length, plural, one {One quote received} other {# quotes received}}',
  'holding.filter-investments-number': '{length, plural, =0 {None} one {One investment} other {# investments}}',
  'portfolio.filter-investments-number-short': '{length, plural, =0 {None} one {One investment} other {#}}',
  'holding.filter-investments-number-short': '{length, plural, =0 {None} other {#}}',
  'portfolio.asAt': 'As at',
  'portfolio.asAtTotalizer': 'As at {date}',
  'portfolio.actionRequired': 'Action Required',
  'portfolio.viewTrades': 'View Trades',
  'portfolio.none': 'None',
  overallValue: 'Overall Value',
  capitalValue: 'Capital Value',
  grossValue: 'Gross Value',
  grossValueWithCurrency: 'Gross Value ({currencySymbol})',
  faceValueWithCurrency: 'Face Value ({currencySymbol})',
  tradeBlotter: 'Trade Blotter',
  mustHaveOneCapitalValue: 'You must have at least one Capital Value',
  capitalValueWithCurrency: 'Capital Value {currency}',
  updateCapitalValue: 'Update Capital Value',
  maturingToday: 'Maturing Today',
  maturingTodayWithCurrency: 'Maturing Today {currency}',
  inProgressRfqs: 'In progress',
  acceptedRfqs: 'Accepted',
  closedRfqs: 'Closed',
  outgoingRfqs: 'Outgoing RFQs',
  outgoingRfqsWithCurrency: 'Outgoing RFQs {currency}',
  newRfq: 'New RFQ',
  rfqTradeSettings: 'Trade Settings',
  noOutgoingRfqs: 'No outgoing RFQs',
  portfolioTitle: 'Portfolio',
  selectMonth: 'Select Month',
  monthlyReports: 'Monthly reports',
  filterResultsMaturing: 'Maturing investments',
  filterResultsMaturingDisabled: 'The maturity filter is only available for the current month',
  filterResultsValue: 'Value',
  filterResultsInvestments: 'Investments',
  filterIsCompliant: 'Is Compliant',
  filterTerm: 'Term',
  show: 'Show',
  hide: 'Hide',
  entries: 'entries',
  invalidCapitalBasisQueryDate: "The date searched does not contain capital basis data. Using today's date.",
  confirmDeleteCapitalValue:
    'Are you sure you want to delete the Capital Value from {asOfDate} with value ${capitalValue, number}?',
};

const validation = {
  required: 'This field must not be empty',
  requiredField: 'Required field',
  empty: 'This field must not be empty',
  'numeric-overflow': 'Value is too large',
  'non-negative-validation': 'Value must be greater than zero',
  'purchase-greater-than-today': 'The purchase date cannot be greater than the current date',
  'sell-greater-than-today': 'The sell date cannot be greater than the current date',
  'maturity-date-lesser-than-today': 'The maturity date cannot be less than the current date',
  'maturity-greater-than-five-years': 'The maturity date cannot be greater than five years',
  'invalid-length': "The field's length is invalid",
  minimumPasswordComplexityRequired:
    'Password must have at least 10 characters, including at least one upper-case (A-Z) and one lower-case letter (a-z), at least one special character (!@-#%^*+) and one number (0-9)',
  'invalid-step': 'The field value must be multiple of ${step, number}',
  'min-validation': 'The field value must be greater than ${min, number}',
  'max-validation': 'The field value must be less than ${max, number}',
  'multiple-validation': 'The field value must be multiple of ${value, number}',
  'not-sellable': "There's nothing to sell",
  'date-interval-validation': 'The date must be before {before} and after {after}',
};

const codes = {
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  semiAnnual: 'Semi-Annual',
  annual: 'Annual',
  atMaturity: 'At Maturity',
  termDeposit: 'Term Deposit',
  general: 'General',
  sewer: 'Sewer',
  water: 'Water',
  td: 'TD',
  cp: 'CP',
  ecd: 'CD',
};

const admin = {
  changePassword: 'Change password',
  currentPassword: 'Current password',
  currentPasswordIsRequired: 'Current password is required',
  newPassword: 'New password',
  newPasswordIsRequired: 'New password is required',
  confirmPassword: 'Confirm password',
  confirmPasswordIsRequired: 'Confirm password is required',
  changePasswordTitle: 'Change Password',
  mustMatchWithNewPassword: 'This field must match with the new password',
};

const notifications = {
  savedSuccessfully: 'Saved successfully',
  deletedSuccessfully: 'Deleted successfully',
  anErrorHasOccurred: 'An error has occurred, please review your data.',
  changedPasswordSuccess: 'New password saved successfully.',
  currentPasswordError: "Could not save password. Please ensure that you've entered your current password correctly.",
  repeatedPassword: 'The new password was already used by you in the past. Please create a different password.',
};

const tradeList = _.mapKeys(
  {
    title: 'Trades Listing',
    from: 'From',
    to: 'To',
    purchaseDate: 'Purchase Date',
    maturityDate: 'Maturity Date',
    coupon: 'Coupon',
    principal: 'Principal',
    id: 'Id',
    interestCompoundCode: 'Interest Compound Code',
    interestPaidCode: 'Interest Paid Code',
    instrumentCode: 'Instrument Code',
    allocationCode: 'Allocation Code',
    issuerId: 'Issuer Id',
    countryCode: 'Country Code',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    reference: 'Reference',
    rootTradeId: 'Root Trade Id',
    instrumentId: 'Instrument Id',
    issuerCode: 'Issuer Code',
    nextCouponDate: 'Next Coupon Date',
    rating: 'Rating',
    portfolioPercentage: 'Portfolio Percentage',
    interests: 'Interests',
    issuer: 'Issuer',
    _links: '_links',
    'issuer.name': 'Issuer',
    'interests.current.accrued': 'Interest',
    'from.invalid': 'This date cannot be greater than the to date',
    'to.invalid': 'This date cannot be lower than the from date',
  },
  (_value, key) => `tradelist.${key}`,
);

const ratesheets = {
  updateRatesheetPageTitle: 'Ratesheet update',
  saveChangedRates: 'Save changed rates',
  updateRatesheet: 'Update ratesheet',
  staleTermDepositRates:
    'Your ratesheet is currently not up to date. For Auto-fill or Auto-quote to work correctly, please select the ”Update ratesheet" button in the top right corner of the screen',
  TDRatesheetsSubtitle: 'Term Deposits - Outright rates',
  CDRatesheetsSubtitle: 'Certificates of Deposit - Outright rates',
  CPRatesheetsSubtitle: 'Corporate Paper - Outright rates',
  segmentCode: 'Segment',
  successUpdate: 'Ratesheet updated successfully!',
  available: 'Available',
  unavailableRatesheet: 'Unavailable ratesheet',
  viewRatesheet: 'View Ratesheet',
  staleRatesheet: 'This ratesheet is stale',
};

const ratesheetColumn = {
  '1M': '1M',
  '2M': '2M',
  '3M': '3M',
  '4M': '4M',
  '5M': '5M',
  '6M': '6M',
  '7M': '7M',
  '8M': '8M',
  '9M': '9M',
  '10M': '10M',
  '11M': '11M',
  '12M': '12M',
  '2Y': '2Y',
  '3Y': '3Y',
  '4Y': '4Y',
  '5Y': '5Y',
};

const sandbox = {
  sandboxPageTitle: 'Sandbox',
  subtitle: 'Trade Sandbox',
  sandboxInstrumentCode: 'Type',
  ratingType: 'Rating used',
  creditRatingCompliant: 'Credit rating',
  creditRatingInvested: 'Rating invested',
  creditRatingAvailable: 'Rating available',
  counterpartyCompliant: 'Counterparty',
  counterpartyInvested: 'Cparty invested',
  counterpartyAvailable: 'Cparty available',
  include: 'Include',
  summaryWeightedAverageCoupon: 'Weighted Average Coupon  (%)',
  summaryWeightedAverageDuration: 'Weighted Average Duration (days)',
  summaryActualPortfolio: 'Actual Portfolio',
  sandboxActual: 'Actual',
  sandboxCalculated: 'Calculated',
  sandboxMaturityLabel: 'Period',
  maturityInvested: 'Maturity invested',
  maturityAvailable: 'Maturity available',
  maturityCompliant: 'Maturity',
  sandboxHoldingPurchaseDate: 'Purchase',
  sandboxHoldingMaturityDate: 'Maturity',
  dollarDifferencePerAnnum: 'Difference p.a.',
  upgrade: 'Up',
  downgrade: 'Down',
  ratingsToolboxLabel: 'Stress Test - Ratings',
  interestRatesToolboxLabel: 'Stress Test - Interest Rates (basis points)',
  resetSandbox: 'Reset sandbox',
  futureDatePicker: 'Future date',
};

const rfqs = {
  rfqsTitle: 'Outgoing RFQs',
  newFundsTitle: 'New funds',
  maturingTitle: 'Maturing Investments',
  rfqsAdditionalFunds: 'Additional Funds',
  rfqsConfirmRedeemTitle: 'Confirm redemption',
  rfqsConfirmReinvestmentTitle: 'Confirm reinvestment',
  rfqsConfirmRedeem: 'Press yes to confirm the redemption of this trade and update the portfolio.',
  rfqsConfirmReinvestment: 'Press yes to confirm the reinvestment of this trade and update the portfolio.',
  reinvest: 'Reinvest',
  redeem: 'Redeem',
  noQuotesForThisRfq: 'No quotes for this RFQ',
};

const rfq = {
  awaitingApproval: 'Awaiting approval',
  amountReinvested: 'Amount to be re-invested',
  areYouSureConfirmNotInterested: 'The other party will be informed that you are not interested.',
  autoFill: 'Auto-fill rates',
  autoFillAreYouSure: 'Are you sure you want to auto fill? The current rates will be overwritten.',
  disabled: 'Disabled',
  bondTitle: 'RFQ - Bond',
  nothingDoneRfq: 'Nothing done',
  dealtAwayRfq: 'Dealt off-platform',
  customer: 'Customer',
  frnTitle: 'RFQ - Floating Rate Note',
  interestPaid: 'Interest Paid',
  allocationCode: 'Allocation',
  issuersToBeQuoted: 'Issuers to be quoted',
  maturitiesToBeQuoted: 'Maturities to be quoted',
  quotesReceived: 'Quotes received',
  acceptSelectedQuote: 'Accept selected rate',
  acceptNominatedQuote: 'Accept nominated rate',
  signOffNominatedQuote: 'Sign-off nominated rate',
  buyOperationCounterparty: 'Sell',
  sellOperationCounterparty: 'Buy',
  nominateSelectedQuote: 'Nominate selected rate',
  confirmSettlementDate: 'Check diary for non-settlement days.',
  confirmSettlementDateTitle: 'Settlement Date',
  startAnRfq: 'Start an RFQ',
  confirmStartRfq: 'Reject the offer and start an RFQ?',
  confirmRedeem: 'Reject the offer and redeem the investment?',
  frequent: 'Frequent',
  rfqNominateConfirm: 'Nominate',
  rfqNominateTitle: 'Nominate Quote {titleSuffix}',
  addNewTradeDealtOff: 'Yes and add trade',
  rfqNominateConfirmation: `  {complianceBreachMessage}

  The select quote will be nominated for acceptance.

  As your organization enforces Segregation of Duties compliance for Quote
  approval, a peer or superior must log on to "accept"
  this quote in order to complete this trade.

  Note that the RFQ will remain open until either the nominated quote is accepted,
  or until the end of the trading day, when it will lapse if not accepted.

    - ADI: {adi}
    - Currency: {currency}
    - Principal: {principal}{consideration}
    - Settlement Date: {settlementDate}
    - Coupon: {coupon}%
    - Maturity Date: {maturityDate}
    - Interest Paid: {interestPaid}
  `,
  rfqSignOffConfirm: 'Sign-off',
  rfqSignOffTitle: 'Sign-off Nominated Quote {titleSuffix}',
  rfqSignOffConfirmation: `  {complianceBreachMessage}

  The select quote will be signed-off for acceptance.

  As your organization enforces Segregation of Duties compliance for Quote
  approval, a peer or superior must log on to "accept"
  this quote in order to complete this trade.

  Note that the RFQ will remain open until either the nominated quote is accepted,
  or until the end of the trading day, when it will lapse if not accepted.

    - ADI: {adi}
    - Currency: {currency}
    - Principal: {principal}{consideration}
    - Settlement Date: {settlementDate}
    - Coupon: {coupon}%
    - Maturity Date: {maturityDate}
    - Interest Paid: {interestPaid}
  `,
  rfqAcceptConfirmTitle: `Confirm {titleSuffix}`,
  rfqAcceptConfirmation: `  {complianceBreachMessage}

    By pressing the "Yes" button below, the following quote will be accepted,
    the RFQ will be closed and won't accept new quotes:

    - ADI: {adi}
    - Currency: {currency}
    - Principal: {principal}{consideration}
    - Settlement Date: {settlementDate}
    - Coupon: {coupon}%
    - Maturity Date: {maturityDate}
    - Interest Paid: {interestPaid}
  `,
  rfqRequestWarningConfirmation: `
The ADI(s) below may break compliance, if you accept their quote:

{issuersNames}

Create RFQ now? When you press ‘Yes’, a request will be sent to the selected ADIs, which will prompt them to enter quotes for the given maturities.
  `,
  rfqAdi: 'ADI',
  rfqAdisSelected: 'ADIs selected',
  ecdIndicativeRates: 'Indicative',
  rfqAdditionalFunds: 'Additional Funds',
  rfqARated: 'A Rated',
  rfqBRated: 'BBB Rated',
  confirmCancelTitle: 'Cancel RFQ',
  nothingDoneCancelBody: 'Cancel this RFQ as "Nothing done"? An email will be sent to all ADIs included in this RFQ',
  dealtAwayAddTradeCancelBody:
    'Press Yes to cancel the RFQ. If you have dealt off-platform and would like to record the trade, please click on “Yes and add trade”',
  dealtAwayCancelBody: `Cancel this RFQ as "Dealt off-platform"? An email will be sent to all ADIs included in this RFQ.

  {extraContent}`,
  dealtAwayCancelBodyWithReinvestment: `You have nominated to “Deal off Platform”. The following actions will now occur:

    - This RFQ will be cancelled
    - The maturing trade will be redeemed on platform
    - An redemption email will be sent to the Issuing Bank to refund the investment
    {extraContent}`,
  confirmCancelMessage: 'Press Yes to cancel this RFQ',
  confirmCancelPositiveAnswer: 'Yes',
  confirmCancelNegativeAnswer: 'No',
  rfqConfirm:
    'Create RFQ now? When you press ‘Yes’, a request will be sent to the selected ADIs, which will prompt them to enter quotes for the given maturities.',
  rfqConfirmOperation: 'Confirm Operation',
  rfqConfirmReinvestmentContent: 'Do you confirm the selected quote?',
  rfqCustomizeDates: 'Review dates',
  rfqDate: 'Date',
  'rfqError-closed-rfq': 'This RFQ has already been closed.',
  'rfqError-cancelled-rfq': 'This RFQ has been cancelled by the customer.',
  'rfqError-duplicated-quote': 'Quote rejected: your institution has already sent a quote for this RFQ.',
  rfqEnterQuotes: 'Incoming RFQs',
  warnOriginalDateDifference:
    'The difference between the chosen tenor date and the custom date is greater than {days} days. {autoFillMessage}',
  warnCustomDateAutoFillDisabled: 'Auto-fill is currently not supported for custom dates',
  rfqInclude: 'Include',
  rfqLong: 'Long',
  rfqMajorRated: 'Major',
  rfqDemoIssuers: 'Demo',
  rfqMaturities: 'Maturities',
  rfqMaturityDate: 'Select Date',
  rfqMinimumIssuersSelected:
    'You cannot select more than {maxRfqIssuerLimit} {maxRfqIssuerLimit, plural, one {ADI} other {ADIs}} per RFQ',
  rfqMonthMaturityToQuote: '{count, plural, one {1 month} other {# months}}',
  rfqSignOff: 'Sign-off succeeded. {remainingSignOffs} remaining for acceptance.',
  rfqCustomDate: 'Custom date',
  rfqMonths: 'In months',
  rfqNoRecords: 'There are no rfqs for today.',
  rfqPrincipalSmaller: 'Principal must not be smaller than the original investment principal',
  rfqBreachCompliance: 'If you accept the quote by this ADI, you may breach compliance.',
  rfqBreachShortTermCompliance: 'If you accept the quote by this ADI, you may breach short term compliance.',
  rfqBreachLongTermCompliance: 'If you accept the quote by this ADI, you may breach long term compliance.',
  rfqNoCompliancesLoaded:
    'Our platform could not perform a compliance check at the moment. The selected ADI may break compliance if its quote is accepted.',
  rfqRequestNoCompliancesWarningConfirmation: `
Our platform could not perform a compliance check at the moment. Some of these ADIs may break compliance if their quotes are accepted:

{issuersNames}

Create RFQ now? When you press ‘Yes’, a request will be sent to the selected ADIs, which will prompt them to enter quotes for the given maturities.
      `,
  rfqAmountAvailableSmallerTitle: 'Compliance breach warning',
  rfqRatingsLimitTooltip: 'Minimum amount available between counterparty and credit rating compliances, as of tomorrow',
  rfqQuotes: 'Quotes',
  rfqHeaderQuoteSentSuccessfully: '{count, plural, one {Quote} other {Quotes}} sent',
  autoQuoted: 'Auto-quoted',
  rfqQuoteSentSuccessfully: '{count, plural, one {Quote} other {Quotes}} sent successfully ',
  rfqQuotesReceived: '{quotes, plural, =0 {Awaiting quotes} one {1 quote received} other {# quotes received}}',
  rfqQuotesSavedSuccessfully:
    'Quotes sent successfully. You will be informed of the outcome of this RFQ by email once it has been dealt with by the customer',
  rfqReinvestment: 'Reinvestment',
  rfqRequest: 'Request Quotes',
  rfqSavedSuccessfully: 'RFQ created',
  rfqSaveFailure: 'Ops! There was a problem during the request',
  rfqAttention: `Attention: This RFQ settles on {settlementDate}`,
  rfqSendConfirmation: `Send quotes now? {settlementDate}`,
  rfqMaturitiesNotFilledContent: 'There are quotes not filled. Are you sure you want to continue and send quotes now?',
  rfqShort: 'Short',
  rfqTitle: 'RFQ',
  newRfqTitle: 'RFQ - New funds',
  rfqUnrated: 'Unrated',
  rfqYearMaturityToQuote: '{count, plural, one {1 year} other {# years}}',
  rfqYears: 'In years',
  tdTitle: 'RFQ - TD',
  ecdTitle: 'RFQ - ECD',
  cpTitle: 'RFQ - CP',
  rfqWarningQuoteBeforeConfirm: "You can't confirm a reinvestment without quotes",
  rfqWarningAcceptOneQuote: 'You must accept one of the quotes before confirming it',
  rfqAcceptSuccessfully: 'Quote accepted successfully',
  rfqClosed: 'RFQ closed',
  rfqDealt: 'Dealt',
  rfqRedeemed: 'Redeemed',
  rfqConfirmError: "Oops, Something went wrong. Your quote wasn't confirmed",
  rfqMonthCode: '{count, plural, one {1 Month} other {# Months}}',
  rfqQuoteNotAcceptedError: "You can only confirm accepted quotes. Your quote wasn't confirmed",
  rfqReinvested: 'Reinvested',
  rfqToReinvest: 'To Reinvest',
  rfqWaiting: 'Waiting response',
  rfqWaitingQuotes: '{count, plural, one {Awaiting quote} other {Awaiting quotes}}',
  rfqYearCode: '{count, plural, one {1 Year} other {# Years}}',
  rfqRespondWithBlank:
    'To indicate that you do not have an interest in quoting, leave respective cells blank and hit "Send"',
  rfqNotInterestedAlert: "If you're not interested on this tenor, leave it blank",
  viewRfq: 'View RFQ',
  requestQuotesToSell: 'Request quotes to sell',
  viewRFQ: 'View RFQ',
  viewPreviousRFQ: 'View Previous RFQ',
  thereIsAnOngoingRfq: 'There is an ongoing RFQ for this trade',
  sendQuote: 'Send Quote',
  sendQuotes: 'Send Quotes',
  sendQuoteNow: 'Send quote now?',
  acceptQuote: 'Accept Quote',
  acceptQuoteNow: 'Accept quote by {issuerCode}?',
  tradeMarginBasisPoints: 'Trading Margin (b.p.)',
  cleanPrice: 'Clean Price',
  grossPrice: 'Gross Price',
  yield: 'Yield (%)',
  quote: 'Quote',
  quoteSentSuccessfully: 'Quote has been sent successfully',
  rfqCancelled: 'Rfq has been cancelled',
  quoteConfirmedSuccessfully: 'Quote has been confirmed',
  buySellBondRfqConfirm: 'Do you confirm {operation, select, buy {selling} sell {buying} other { }} this instrument?',
  sellBondCreateRfqConfirmation:
    'You are about to create an RFQ for selling {value} in this security {issuerCodesLength, plural, =0 {. No Bank Selected} one {for bank {issuerCodes}} other {for banks {issuerCodes}}}. Are you sure?',
  availableQuotes: '{count, plural, one {1 quote} other {# quotes}} available',
  party: 'C. Party',
  unavailable: 'Unavailable',
  isinYieldOrMargin: '{instrumentCode, select, BOND {Yield (%)} FRN {Trading Margin (bp)} other {Other}}',
  'rfq-closed': 'The rfq is closed',
  'rfq-not-opened': 'The rfq has not been opened',
  'cancelled-rfq': 'The rfq has been cancelled',
  'rfq-expired': 'The rfq has expired',
  'invalid-issuer-code': 'Invalid issuer code',
  'invalid-value': 'Invalid value',
  'waiting-confirmation': 'Waiting confirmation',
  expired: 'Expired',
  ongoing: 'Ongoing',
  cancelRfq: 'Cancel RFQ',
  cancelRfqNow: 'Cancel RFQ now?',
  selling: 'Selling',
  securities: 'Securities',
  isin: 'ISIN',
  issuer: 'Issuer',
  expiresAt: 'Expires At',
  tradeDate: 'Trade Date',
  quotes: 'Quotes',
  view: 'View',
  buySellBondRfqConfirmedBy: 'Confirmed by: {confirmedBy} at {confirmedAt}',
  markNotInterested: 'Mark as not interested',
  rfqQuoteNotFilledTitle: 'There are quotes not filled.',
  rfqQuoteNotFilledSubtitle: 'Are you sure you want to continue and send quotes now?',
};

const buyBondRfq = {
  buyBondCreateRfqConfirmation:
    'You are about to create an RFQ for buying {buyingValue} in this security {issuerCodesLength, plural, =0 {. No Bank Selected} one {for bank {issuerCodes}} other {for banks {issuerCodes}}}. Are you sure?',
  buyBondCreateRfq: 'Create RFQ',
  buyBondRequestQuotesToBuy: 'Request quotes to buy',
  buyBondRfqSendQuotesTitle: 'Quote',
  doYouHaveAustraclear: 'Settle via Austraclear',
  safeCustodyWithAustraclear: 'Safe custody with Austraclear',
};

const confirmDialog = {
  defaultCancelButton: 'No',
  defaultConfirmButton: 'Yes',
  defaultConfirmContent: 'Are you sure?',
  defaultConfirmTitle: 'Confirm',
};

const password = {
  emailSent: 'An email was sent to the address provided, with further instructions.',
  forgotPassword: 'Forgot password',
  forgotPasswordInstructions: 'Please provide the email you use to login.',
  invalidToken:
    'Reset password token is invalid, or expired. Please go back to the "Forgot password" page and start again.',
  passwordChanged: 'You password was successfully changed. You will be redirected to the login page.',
  repeatedPassword:
    'Attention: Password was not changed. New password must be different than a previously used password.',
  resetPassword: 'Create new password',
  passwordsDoNotMatch: 'Passwords do not match',
  newPasswordRequired: 'New password is required',
  passwordConfirmationRequired: 'Confirmation password is required',
  emailRequired: 'Email is required',
  invalidEmail: 'Invalid email address',
  email: 'Email',
};

const hasMaturitiesNotDealtWith = {
  hasMaturitiesNotDealtWithTitle: 'Maturities not dealt with',
  hasMaturitiesNotDealtWithContent: `There {notDealtWith, plural, one {is one investment} other {are multiple investments}} maturing today.
If you want to redeem or reinvest {notDealtWith, plural, one {this} other {any}} maturing investment, click on "Go to Maturing Investment" button below.
If this RFQ relates to a new investment, click on "New RFQ" button below`,
  hasMaturitiesNotDealtWithCancel: 'New RFQ',
  hasMaturitiesNotDealtWithConfirm: 'Go To Maturing Trades',
};

const offers = {
  add: 'Add',
  added: 'Added',
  addOrRemoveFunds: 'Add or Remove Funds',
  additionalFunds: 'Additional Funds',
  partialRedeem: 'Partial Redeem',
  amountToReinvest: 'Amount to reinvest',
  amountMustBeLowerThanPrincipal: 'Amount must be lower than {currencySymbol}{principal}',
  coupon: 'Coupon',
  confirmReinvestment: 'Confirm Reinvestment',
  reinvestment: 'Reinvestment',
  notSelected: 'Not Selected',
  offerAccepted: 'Offer selected successfully',
  confirmTenorMaturityDate: 'Check for non-settlement days.',
  offerAccrued: 'Accrued',
  status: 'Status',
  offerAccruedInterest: 'Accrued Interest',
  offerAccruedInterestAdded: 'Accrued Interest Added',
  offerTenorM: '{count, plural, one {1 month} other {# months}}',
  offerTenorY: '{count, plural, one {1 year} other {# years}}',
  updatedAt: 'Ratesheet last updated at: {value}',
  selectARate: 'Select a rate',
  messageSent: 'Message sent',
  viewMessageSent: 'View message',
  issuerTradeDetails: 'Details',
  maturingDeal: 'Maturing Deal',
  oneDayRolloverSuccessful: 'The trade has been rolled over successfully',
  redeemSuccessfully: 'The trade has been redeemed successfully',
  rejectOfferSuccessfully: 'The offer has been rejected',
  reinvestSuccessfully: 'The trade has been reinvested successfully',
  tradeCreatedSuccessfully: 'The trade has been created successfully',
  confirmRedeemOnBehalfTitle: 'Confirm Redeem',
  confirmRedeemOnBehalfContent: 'Are you sure that you want to Redeem on behalf of the Investor?',
  cancelOngoingOffer: 'Cancel offer',
  cancelOngoingOfferSuccessfully: 'The offer has been cancelled successfully',
  confirmCancelOngoingOfferBeforeReinvest: 'Cancel Ongoing Offer Before Reinvest',
  cancelOfferMessage: 'The previously sent offer will be cancelled.',
  cancelOfferTitle: 'Cancel Offer',
  cancelOfferContent: 'Are you sure that you want to Cancel the Offer?',
  selectedOffer: 'Selected Offer',
  selectedRate: 'Selected Rate',
  addFundsTitle: 'Add funds',
  oneDayRolloverTitle: 'One day rollover',
  confirmMaturityDateTitle: 'Tenor Maturity Date',
  adjustMaturityDate: 'Adjust Maturity Date',
  adjustRate: 'Adjust Rate',
  rfqInProgress: 'RFQ in progress',
  alternativeActions: 'Alternative actions',
  offerExpiredTitle: `This offer has expired.`,
  offerExpiredInfo: `The offer from {sentBy} to reinvest {principal, number} has expired.\n
  Please contact {sentBy} directly to discuss your maturing investment.`,
  noRatesheetAvailableInfo: `The bank has no ratesheet currently available to your segment.\n
  Please contact {sentBy} directly to discuss your maturing investment.`,
  bankNoRatesheetAvailableInfo: 'Your bank has no ratesheet currently available to segment {segment}.',
  offerReceived: 'Offer received',
  notInterested: 'Not interested',
  areYouSureThatYouAreNotInterested: 'Are you sure that you are not interested?',
  areYouSureToStartAnRfq: 'Are you sure that you want to start an RFQ?',
  offerRejected: 'Offer marked as not interested',
  optionSelected: 'Option selected',
  sentBy: 'Sent By',
  acceptedBy: 'Accepted By',
  reinvestedBy: 'Reinvested By',
  rejectedBy: 'Rejected By',
  redeemedBy: 'Redeemed By',
  redeemedAt: 'Redeemed At',
  rejected: 'Rejected',
  approved: 'Approved',
  duplicated: 'Duplicated',
  noRatesheet: 'No Ratesheet',
  ratesheet: 'Ratesheet',
  customRatesheet: 'Custom ratesheet',
  response: 'Response',
  historyMarketData:
    'Market Data History - {tenorNumber} {tenorPeriod, select, M {{tenorNumber, plural, one {Month} other {Months}}} Y {Years} other {}}',
  rfqWonByAdi: 'RFQ won by your ADI',
};

const segments = {
  bank: 'Bank',
  corp: 'Corp',
  fund: 'Fund',
  localGov: 'Local Gov',
};

const statuses = {
  'rate-chosen': 'Rate Chosen',
};

const tour = {
  backTour: 'Back',
  closeTour: 'Close',
  lastTour: 'Last',
  nextTour: 'Next',
  skipTour: 'Skip',
  startTour: 'Start Tour',
  portfolioAsAt: 'You can see your portfolio on any date in the past or get a preview of how it will be in the future.',
  portfolioActions: 'The buttons to create a Trade and RFQs are now one click away.',
  portfolioPrimaryTabs:
    'This menu bar displays your workflow options. Interactive indicators highlight actions are required to be undertaken. Click on each menu item to review.',
  portfolioRfqsTab:
    'You will find ongoing and past RFQs here. In the New RFQ screen, a "Frequent" tab will list all the issuers that you usually include in RFQs, for easier access',
  portfolioRefreshButton: 'You can reload your portfolio without refreshing the entire screen.',
  portfolioInformationButton:
    'You can replay this guide at any time, as well as send us feedback or questions, by clicking on this button.',
  hamburgerButton: 'The logout option is now inside this menu',
  sendQuotesDrawer: 'For the RFQ Response pop-out drawer, there are 3 panels, each divided into 2 sections.',
  sendQuotesBasicInfos:
    'The 1st panel is the RFQ / Quote panel. The top section of the panel displays the details of the RFQ from the customer.',
  sendQuotesAutofill:
    "The second section enables the Dealer to respond to the RFQ. {breakline}At the top of this section, the system displays the status of the current ratesheet. The dealer can determine if the ratesheet is 'stale' and requires updating. {breakline}The Dealer can choose to auto-fill the proposed quotes for each tenor directly from the ratesheet, or manually enter the rates.",
  sendQuotesSendQuote:
    'If the dealer nominates to "auto-fill", rates will populate each respective tenor from the latest ratesheet. These rates can be editied before responding to the RFQ. Alternatively, the Dealer can manually enter each rate for each tenor.',
  sendQuotesHistoryByTenor: `The right-side panel dynamically displays historical pricing data.{breakline}
  This panel is divided into 2 sections.
  The top section of this panel displays quotes from the Bank related to the current Customer.
  The bottom section of this panel displays quotes from the Bank related to the industry segment of the current Customer.{breakline}{breakline}

  As the Dealer clicks into each Tenor in the Quoting panel on the left, the Dynamic data panel will display the Bank's quotes for the last 5 trades for the selected tenor.`,
  sendQuotesHistoryByDate:
    'The middle panel is dynamic data that displays the last 5 successful trades by both the Customer and their Industry Segment.',
  sendQuotesSendQuotesReset: 'You can replay this guide at any time by clicking on this button.',
};

const licencedFeatures = {
  defaultLicencedFeatureTitle: 'This is a licensed feature',
  defaultLicenceRequestInfo: 'Request information',
  defaultLicencedFeatureMessage:
    'If you would like to know more about this feature and how to enable it, please contact Imperium Markets or click on the button below and an enquiry message will be sent on your behalf.',
  incomingRfqHistoryFeatureMessage: `This feature allows you to access the full trade history of the ADI: RFQs won and lost (with competitive data), rollover offers, operations done  on behalf of the investor. This data is also available for download as a CSV file (Excel).

Please click on the button below if you would like to be contacted by Imperium Markets and know more.`,
  incomingRfqHistoryFeatureTitle: 'Trade History',
};

const unsolicitedRatesheet = {
  unsolicitedRatesheet: 'Digital Ratesheets',
  defaultUnsolicitedRatesheetCreatedToday: 'Digital Ratesheet Created Today',
  bespokeUnsolicitedRatesheetCreatedToday: 'Bespoke Digital Ratesheet Created Today',
  updateRatesheet: 'Update ratesheet',
  sendRatesheet: 'Send ratesheet',
  open: 'Open',
  pending: 'Pending',
  ongoing: 'Ongoing',
  won: 'Won',
  lost: 'Lost',
  'nothing-done': 'Nothing done',
  dealtAway: 'Dealt off-platform',
  cancelled: 'Cancelled',
  closed: 'Closed',
  'bank-notified': 'Pending',
  sendDefaultRatesheets: 'Send default ratesheets',
  sendDefaultRatesheetsSuccessfully: 'Default ratesheets have been sent successfully',
  sendBespokeRatesheetsSuccessfully: 'Bespoke ratesheets have been sent successfully',
  tradeFromRateCreatedSuccessfully: 'Trade has been created successfully',
  sendBespokeRatesheets: 'Send bespoke ratesheets',
  sendUnsolicitedRatesheetTitle: 'Send Digital Ratesheet',
  default: 'Default',
  bespoke: 'Bespoke',
  dealer: 'Dealer',
  invested: 'Invested',
  adi: 'ADI',
  unsolicitedRatesheetCustomers: 'Customers',
  unsolicitedRatesheetCollation: 'Collation',
  unsolicitedRatesheetRequestRfq: 'Request For Quote',
  unsolicitedRatesheetDeal: 'Deal',
  unsolicitedRatesheetDealConfirmation:
    'By clicking on yes, a trade with principal <b>{principal}</b> using rate <b>{rate}</b> for maturity date <b>{maturityDate, date}</b> will be created in your portfolio. Are you sure?',
  unsolicitedRatesheetDealReinvestmentConfirmation:
    'By clicking on yes, you are reinvesting the trade <b>{sourceTrade}</b>. Are you sure?',
  createTradeFromRate:
    'Create Trade from tenor <b>{tenor}</b> using rate <b>{rate}</b> for maturity date <b>{maturityDate, date}</b>',
  sendBespokeUnsolicitedRatesheet: 'Send unsolicited bespoke ratesheet?',
  unsolicitedRatesheetDetails: 'Digital Ratesheet Details',
  unsolicitedRatesheetType: 'Type',
  unsolicitedRatesheetOnBehalf: 'On Behalf',
  createdTrades: 'Created Trades',
  hasTrades: 'Has Trades',
  tradesCount: 'Number of trades',
  showCurrentRatesheet: 'Show current ratesheet',
  currentRatesheet:
    'Current {type} ratesheet <small>updated at {updatedAt, date, short} {updatedAt, time, short}</small>',
  'unsolicited-ratesheet-status-created-trades':
    '{count, plural, =0 {Bespoke Sent} one {1 trade created} other {# trades created}}',
  unsolicitedRatesheetHasMaturitiesNotDealtWith: `If you want to reinvest {maturingTodayNotDealtWith, plural, one {this} other {any}} maturing investment, click on "Reinvest" button below.

If this UR relates to a new investment, click on "New Funds" button below`,
  unsolicitedRatesheetReinvest: 'Reinvest',
  unsolicitedRatesheetNewFunds: 'New Funds',
  unsolicitedRatesheetMaturityDate: 'Maturity: <b>{maturityDate, date}</b>',
  sourceTrade: 'Source Trade',
  customerType: 'Customer Type',
  unsolicitedRatesheetBank: 'Bank',
  unsolicitedRatesheetNoBankMaxAmount: 'You currently have no max amount set to restrict deals',
  unsolicitedRatesheetMaxBankAmount: 'Max Amount',
  unsolicitedRatesheetDisabledNoRecords: 'Digital Ratesheets is turned off',
  unsolicitedRatesheetMaxBankAmountLabel: 'Max Amount for instant deal',
  unsolicitedRatesheetMaxBankAmountPlaceholder: 'Enter max amount',
  unsolicitedRatesheetMaxBankAmountWithValue: 'Max Amount: {value}',
  unsolicitedRatesheetMaxBankAmountPopoverTitle: 'Set the max allowed amount to receive an instant deal',
  unsolicitedRatesheetMaxAmountReached: 'Value greater than the max amount allowed by the bank',
  unsolicitedRatesheetMaxAmountReachedIssuer: 'Value greater than the max amount allowed',
  unsolicitedRatesheetMaxAmountReachedIssuerAreYouSure:
    'The given value is greater than the Maximum $ Limit for Ratesheet. Are you sure to create this trade?',
  unsolicitedRatesheetMaxAmountReachedWithAdditionalFunds: 'Additional and reinvestment reached amount allowed',
  unsolicitedRatesheetLong: '(Long)',
  unsolicitedRatesheetShort: '(Short)',
};

const onboarding = {
  inProgress: 'In progress',
  approved: 'Approved',
  rejected: 'Rejected',
  addSingleCustomer: 'Add single customer',
  addMultipleCustomers: 'Add multiple customers',
  addRow: 'Add row',
  back: 'Back',
  upload: 'Upload',
  downloadLower: 'download',
  showDetailedErrors: 'Show detailed errors',
  dropFileHere: 'Drop your file here',
  uploadCsvFile: 'Upload CSV file',
  updateListReference: 'To properlly update your list you can use the ratesheet reference we have available for',
  customerCreatedError: 'Error! There are some invalid data.',
  customerCreatedSuccessfully: 'Customers added successfully.',
  entity: 'Entity',
};

const tradeSourcesLabels = {
  'new-funds': 'New Funds',
  [tradeSources.rolloverOffer]: 'Rollover Offer',
  [tradeSources.defaultUnsolicitedRatesheet]: 'Default UR',
  [tradeSources.bespokeUnsolicitedRatesheet]: 'Bespoke UR',
  [tradeSources.rfq]: 'RFQ',
};

const messages = {
  ...onboarding,
  ...admin,
  ...allocation,
  ...assets,
  ...codes,
  ...confirmDialog,
  ...compliances,
  ...counterparty,
  ...creditQuality,
  ...dashboard,
  ...download,
  ...errors,
  ...filter,
  ...holding,
  ...interestDescription,
  ...licencedFeatures,
  ...maturity,
  ...menu,
  ...needToConfirm,
  ...notifications,
  ...password,
  ...portfolio,
  ...rfq,
  ...rfqs,
  ...term,
  ...upload,
  ...validation,
  ...notifications,
  ...tradeList,
  ...reports,
  ...ratesheets,
  ...ratesheetColumn,
  ...sandbox,
  ...hasMaturitiesNotDealtWith,
  ...offers,
  ...statuses,
  ...segments,
  ...tour,
  ...buyBondRfq,
  ...unsolicitedRatesheet,
  ...tradeSourcesLabels,
  accept: 'Accept',
  addANote: 'Add a note <small>(Optional)</small>',
  addYourNoteHere: 'Add your note here',
  accepted: 'Accepted',
  addFunds: 'Add funds',
  addTradeOnBehalf: 'New funds',
  areYouSure: 'Are you sure?',
  sendResponse: 'Send response',
  complianceLimits: 'Counterparty exposure compliance limits',
  compliancePolicyPreventsAcceptance: 'Compliance policy requires additional quotes',
  waitingForMaturity: 'Waiting for maturity',
  intendToRedeem: 'Intend to redeem',
  other: 'Other',
  doNotTakeOffer: 'Do not take this offer',
  reinvestedOnBehalf: 'Reinvested on behalf',
  redeemedOnBehalf: 'Redeemed on behalf',
  cancelledRfq: 'Cancelled RFQ',
  actioned: 'Actioned',
  actions: 'Actions',
  action: 'Action',
  addNew: '{count, plural, =0 {Add Capital Value} other {Add New}}',
  all: 'All',
  All: 'All',
  amountMaturing: 'Maturing',
  anErrorHasOccurredPleaseTryAgain: 'An internal error has occurred. Please try again later.',
  anErrorHasOcurred: 'An error has occurred.',
  anErrorHasOcurredWithValues: 'An error has occurred: {errorMessage}',
  refresh: 'Refresh',
  at: 'at ',
  body: 'Body',
  by: 'By',
  cancel: 'Cancel',
  cancelled: 'Cancelled',
  cleanFilterTooltip: 'Reset filters',
  createdAt: 'Created at',
  clear: 'Clear filters',
  close: 'Close',
  closed: 'Closed',
  code: 'Code',
  completed: 'Completed',
  compliant: 'Compliant',
  confirm: 'Confirm',
  confirmation: 'Confirmation',
  confirmed: 'Confirmed',
  currency: 'Currency',
  currencyShort: 'Ccy',
  customer: 'Customer',
  customLongLimit: 'Long Limit ({currencySymbol})',
  customShortLimit: 'Short Limit ({currencySymbol})',
  customerName: 'Customer name',
  customerId: 'Customer ID',
  abn: 'ABN',
  dashboard: 'Dashboard',
  details: 'Details',
  date: 'Date',
  daysOfInvestment: 'Days of investment',
  difference: 'Difference',
  downloadTrades: 'Download Trades',
  emailQueued: 'The requested email has been queued for sending.',
  end: 'End',
  excel: 'Microsoft Excel ®',
  filter: 'Filter',
  format: 'Format',
  ratesheetLastUpdatedAtWithValues: 'Ratesheet last updated: {value}',
  lastUpdatedAtWithValues: 'Last updated at: {value}',
  sendRatesheetLastUpdatedAt: 'Send ratesheet last updated at: {value}',
  usingCustomRatesheet: 'You are using a custom ratesheet for this offer.',
  filterBy: 'Filter by',
  from: 'From',
  inDate: 'In date',
  inDays: '{days, plural, one {1 day } other {# days }}',
  insertValue: 'Insert value',
  investments: 'Investments',
  issuers: 'Issuers',
  limit: 'Limit',
  limits: 'Limits',
  logout: 'Logout',
  longLimit: 'Long Limit',
  lost: 'Lost',
  maturingTrades: 'Maturing Trades',
  maturing: 'Maturing',
  numberOfTransactions: 'Number of transactions',
  loading: 'Loading',
  maturingUntil: 'Maturing until',
  maxLimit: 'Max Limit',
  minLimit: 'Min Limit',
  newQuotes: 'Please check quote responses as details have been updated.',
  name: 'Name',
  no: 'No',
  noGraphRecords: "There's no data to build a graph.",
  nonCompliant: 'Non-compliant',
  noRecords: 'There are no records which match your filters.',
  noData: 'There is no data currently',
  notReceived: 'Not received',
  ok: 'Ok',
  open: 'Open',
  or: 'Or',
  orderBy: 'Order by',
  pdf: 'PDF',
  pending: 'Pending',
  portfolioReportsAvailableForCustomers: 'These reports are available for Imperium Markets subscription customers',
  rating: 'Rating',
  reference: 'reference',
  receivedAt: 'Received at',
  redeemed: 'Redeemed',
  contactUs: 'Contact Us',
  rfqs: 'RFQs',
  compliance: 'Compliance',
  sandbox: 'Sandbox',
  holdings: 'Holdings',
  downloads: 'Downloads',
  trades: 'Trades',
  selectPeriod: 'Select period',
  current: 'Current',
  history: 'History',
  unsettled: 'Unsettled',
  allocation: 'Allocation',
  assets: 'Assets',
  reinvested: 'Reinvested',
  rfqCreatedBy: 'Initiated by: {createdBy} - {createdByEmail}',
  reset: 'Reset',
  save: 'Save',
  search: 'Search',
  segment: 'Segment',
  select: 'Select',
  selected: 'Selected',
  send: 'Send',
  originalCoupon: 'Original coupon',
  sendEmail: 'Send email',
  redemptions: 'Redemptions',
  rfq: 'RFQ',
  offer: 'Offer',
  sendEmailToInvestor: 'Send offer to {customerName}',
  noRatesheetAvailable: 'No ratesheet available.',
  settlementDate: 'Settlement Date',
  shortLimit: 'Short Limit',
  start: 'Start',
  status: 'Status',
  interestPaid: 'Interest Paid',
  selectInterestPaid: 'Select frequency',
  read: 'Read',
  sent: 'Sent',
  subject: 'Subject',
  support: 'Support',
  tenor: 'Tenor',
  tenors: '{length, plural, one {1 tenor} other {# tenors}}',
  term: 'Term',
  to: 'To',
  total: 'Total',
  tradesCount: 'Number of trades',
  unactioned: 'Unactioned',
  unconfirmed: 'Unconfirmed',
  pendingConfirmationByInvestor: "This trade has been redeemed and it's pending the confirmation by the investor.",
  waiting: 'Waiting',
  operation: 'Operation',
  rate: 'Rate: <b>{rate}%</b>',
  buy: 'Buy',
  sell: 'Sell',
  wam: 'WAM',
  way: 'WAY',
  won: 'Won',
  yes: 'Yes',
  yourMessageHere: 'Your message here',
  sendOffer: 'Send offer',
  viewOffer: 'View offer',
  sendOfferNow: 'Send offer now?',
  sendOfferWithCustomRatesheet: 'Send offer with custom ratesheet?',
  reason: 'Reason',
  investorMessage: 'Investor message',
  note: 'Note',
  oneDayRollover: 'One day rollover',
  sendOfferToInvestor: 'Send offer to investor',
  emailCharactersCount: 'Characters count',
  passwordWillExpire: 'Your password will expire in {days} day(s). <a>Click here to change it now</a>.',
  passwordIsExpired: `Your password has expired, please change it now.`,
  checkTwoFactorCodeEmail: 'Check your email for the MFA code to complete sign-in.',
  invalidTwoFactorCode: 'Invalid two factor code.',
  'invalid-login-or-password': 'Invalid login and/or password.',
  'disabled-user':
    'Your account is currently disabled. Please contact tradesupport@imperium.markets if you want your account to be reactivated.',
  'pending-user':
    'Your account is currently pending. Please contact tradesupport@imperium.markets if you want your account to be reactivated.',
  backToLoginPage: 'Back to login',
  'account-unused-time-limit':
    'This account has been temporarily disabled.<br />Please contact support if you need any assistance.',
  toShouldBeSmallerThanFrom: 'The "To" date should be greater than the "From" date',
  shouldNotBeGreaterThanAYear: 'The range should not be greater than a year',
  tooManyFailedLoginAttempts:
    'Your account is temporarily locked due to too many failed login attempts. Click here to reset your password now.',
  updateNoteAndReference: 'Update note and reference',
  valuationDate: 'Valuation Date',
  yieldOrTradeMargin: 'Yield/Trade Margin',
  emptyDefaultMessage: 'There are no records.',
  feedbackFieldLabel: 'Feedback',
  feedbackTitle: 'Your feedback is very important',
  feedbackText:
    'Your feedback, questions and suggestions are much appreciated. You can also reach us at any time, at tradesupport@imperium.markets.',
  feedbackSentSuccessfully: 'Thank you! Feedback sent.',
  sendFeedbackEmail: 'Send feedback',
  replayGuide: 'Replay guide',
  user: 'User',
  kyc: 'KYC',
  autoQuotingOn: 'Auto Quoting: On',
  autoQuotingOff: 'Auto Quoting: Off',
  unsolicitedRatesheetOn: 'Digital Ratesheets On',
  unsolicitedRatesheetOff: 'Digital Ratesheets Off',
  customTable: 'Custom Table',
  tableSettings: 'Table Settings',
  viewData: 'View Data',
  pleaseContactSupport: 'An error has occurred Please contact tradesupport@imperium.markets.',
  hasError: 'Has Error',
  processing: 'Processing',
  historyByTenor: 'History by tenor',
  segmentHistoryByTenor: 'Industry Segment History By Tenor',
  lastTrades: 'Last 5 Trades',
  showByTenor: 'Show By Tenor',
  today: 'Today',
  firstName: 'First Name',
  surname: 'Surname',
  lastName: 'Last Name',
  age: 'Age',
  city: 'City',
  countryCode: 'Country Code',
  uploadFile: 'Upload File',
  downloadFile: 'Download',
  attachBankConfirmation: 'Attach Bank Confirmation',
  adiCurrentlyNotSeeking: 'ADI is not currently seeking funding',
  entityTooLarge: 'The upload is too large, the maximum size is {maxUploadSize}.',
  anzsciCode: 'ANZSIC Code',
  maturitiesNotDealt: 'Not dealt with',
};

export default messages;
