import './update-ratesheet-button.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Column } from 'react-display-flex';
import { FaExclamationTriangle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import {
  fetchRatesheets as fetchRatesheetsAction,
  updateRatesheets as updateRatesheetsAction,
} from '../../../../../actions/ratesheets';
import { isAdmin } from '../../../../../actions/session-selector';
import { Tooltip } from '../../../../../components/common';
import { isBeforeToday, newDate } from '../../../../../date';
import { getBankRatesheet, getTdRatesheetLastUpdatedAt } from '../../../../../ducks/ratesheet/selectors';
import { UpdateRatesheetDrawer } from './UpdateRatesheetDrawer';

const UpdateRatesheetComponent = ({
  bankRatesheets,
  tdRatesheetLastUpdatedAt,
  tooltipPlacement,
  isUserAdmin,
  updateRatesheets,
  fetchRatesheets,
}) => {
  const [isUpdateDrawerOpen, setIsUpdateDrawerOpen] = useState(false);
  const [isRatesheetStale, setIsRatesheetStale] = useState(false);
  const [lastUpdatedDate, setLastUpdatedDates] = useState(newDate(tdRatesheetLastUpdatedAt));

  useEffect(() => {
    if (tdRatesheetLastUpdatedAt) {
      setIsRatesheetStale(isBeforeToday(tdRatesheetLastUpdatedAt));
      setLastUpdatedDates(newDate(tdRatesheetLastUpdatedAt));
    }
  }, [tdRatesheetLastUpdatedAt]);

  const onUpdateRatesheet = () => {
    fetchRatesheets();
    setIsUpdateDrawerOpen(false);
  };

  if (!isUserAdmin) {
    return null;
  }

  return (
    <Column>
      <Tooltip
        id="updateRatesheetButton"
        placement={tooltipPlacement || 'top'}
        tooltipComponent={
          <FormattedMessage
            id="ratesheetLastUpdatedAtWithValues"
            values={{
              value: `${lastUpdatedDate.fromNow()} at ${lastUpdatedDate.format('LT')}`,
            }}
          />
        }
      >
        <Button
          type="button"
          onClick={() => setIsUpdateDrawerOpen(true)}
          className={classNames({ 'should-update-ratesheet': isRatesheetStale })}
        >
          {isRatesheetStale && <FaExclamationTriangle />} <FormattedMessage id="updateRatesheet" />
        </Button>
      </Tooltip>
      <UpdateRatesheetDrawer
        isOpen={isUpdateDrawerOpen}
        ratesheets={bankRatesheets}
        updateRatesheets={updateRatesheets}
        onUpdateRatesheet={onUpdateRatesheet}
        onClose={() => setIsUpdateDrawerOpen(false)}
      />
    </Column>
  );
};

UpdateRatesheetComponent.propTypes = {
  bankRatesheets: PropTypes.shape(),
  tooltipPlacement: PropTypes.oneOf(['top', 'bottom']),
  updateRatesheets: PropTypes.func.isRequired,
  fetchRatesheets: PropTypes.func.isRequired,
  isUserAdmin: PropTypes.bool,
  tdRatesheetLastUpdatedAt: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isUserAdmin: isAdmin(state),
  bankRatesheets: getBankRatesheet({ ratesheets: state.ratesheets }),
  tdRatesheetLastUpdatedAt: getTdRatesheetLastUpdatedAt({ ratesheets: state.ratesheets }),
});

const mapDispatchToProps = { updateRatesheets: updateRatesheetsAction, fetchRatesheets: fetchRatesheetsAction };

export const UpdateRatesheetButton = connect(mapStateToProps, mapDispatchToProps)(UpdateRatesheetComponent);
